exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mein-angebot-js": () => import("./../../../src/pages/mein-angebot.js" /* webpackChunkName: "component---src-pages-mein-angebot-js" */),
  "component---src-pages-methoden-js": () => import("./../../../src/pages/methoden.js" /* webpackChunkName: "component---src-pages-methoden-js" */),
  "component---src-pages-ueber-mich-js": () => import("./../../../src/pages/ueber-mich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */)
}

